import React from 'react'
import PropTypes from 'prop-types'
import { GatsbyImage } from "gatsby-plugin-image"


const PreviewCompatibleImageArtiste = ({ imageInfo }) => {

  const { alt = '', childImageSharp, image, maxWidth } = imageInfo
  const imageStyle = { borderRadius: '0px', margin: 'auto', height : "250px", width:"100%", objectFit: "cover", justifyContent: "center", alignItems: "center", alignSelf: "center"   }

  // classical image :
  if (!!image && !!image.childImageSharp) {
    return (
      <GatsbyImage className="feature_image is-flex is-justify-content-center is-centered is-center" image={image.childImageSharp.gatsbyImageData} style={imageStyle}  alt={alt} />
    )
  }

  if (!!childImageSharp) {
    return <GatsbyImage className="feature_image is-flex is-justify-content-center" image={childImageSharp.gatsbyImageData} style={imageStyle} alt={alt} />
  }

  if (!!image && typeof image === 'string')
    return <GatsbyImage className="feature_image is-flex is-justify-content-center" style={imageStyle} src={image} alt={alt} />

  return null
}

PreviewCompatibleImageArtiste.propTypes = {
  imageInfo: PropTypes.shape({
    alt: PropTypes.string,
    childImageSharp: PropTypes.object,
    image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]).isRequired,
    style: PropTypes.object,
  }).isRequired,
}

export default PreviewCompatibleImageArtiste
