import React, { useEffect, useState } from "react";
import Layout from '../../components/layout/Layout'
import { useIsMobile } from "../../hooks/useIsMobile";
import { getReservationOfTheWeek } from "../../services/ceramique-reservation";
import { HonestWeekPicker } from "../../components/calendrier/WeekPicker";
import moment from "moment";
import { endOfWeek, startOfWeek } from "date-fns";
import CalendarDay from "../../components/calendrier/CalendarDay";
import { ArrowLeft } from "../../icons/ArrowLeft";
import { ArrowRight } from "../../icons/ArrowRight";
import { useReservations } from "../../context/CalendrierContext";
import { getLastNews } from "../../services/news";
import { navigate } from "gatsby-link";

const AddNewsPage = () => {
  moment.locale('fr');

  const handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value })
  }

 const handleSubmit = (e) => {
    e.preventDefault()
    const form = e.target
    fetch('/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: {
        'form-name': form.getAttribute('name'),
        ...this.state,
      },
    })
      .then(() => navigate(form.getAttribute('action')))
      .catch((error) => alert(error))
  }


  return (
    <Layout>
      <section className="section" style={{backgroundColor : "#ffffff", paddingTop : "0rem"}}>
        <div className="container">
          <div className="content">
            <div className="news-form">
              <form
                name="contact"
                method="post"
                action="/contact/merci/"
                data-netlify="true"
                data-netlify-honeypot="bot-field"
                onSubmit={handleSubmit}
              >
                {/* The `form-name` hidden field is required to support form submissions without JavaScript */}
                <input type="hidden" name="form-name" value="contact" />
                <div hidden>
                  <label>
                    Don’t fill this out:{' '}
                    <input name="bot-field" onChange={handleChange} />
                  </label>
                </div>
                <div className="field">
                  <label className="label" htmlFor={'name'}>
                    FontSize
                  </label>
                  <div className="control">
                    <input
                      className="input"
                      type={'number'}
                      name={'fontSize'}
                      onChange={handleChange}
                      id={'fontSize'}
                      required={true}
                    />
                  </div>
                </div>
                <div className="field">
                  <label className="label" htmlFor={'message'}>
                    Texte
                  </label>
                  <div className="control">
                    <textarea
                      className="textarea"
                      name={'message'}
                      onChange={handleChange}
                      id={'message'}
                      required={true}
                    />
                  </div>
                </div>
                <div className="field">
                  <button className="button has-text-terracota is-link" style={{ backgroundColor: 'black'}} type="submit">
                    Envoyer
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
    </Layout >
  )}


export default AddNewsPage
